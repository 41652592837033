import React from "react"

/* Import Global Components */
import VoicePicker from "~components/voicePicker"

/* Import Local Components */
import Slide from "../../components/slide"

/* Import Local Styles */
import "./voices.css"

const VoicesSlide = () => {
  const goToNextSlide = () => {
    if (window && window.fullpage_api) {
      window.fullpage_api.moveSlideRight()
    }
  }

  return (
    <Slide title="voices">
      <div className="voices-container">
        <div>
          <div>
            <p>Pick a Voice Over</p>
          </div>
          <div className="voice-pickers">
            <VoicePicker newVoice={"Distinguished"} callback={goToNextSlide} />
            <VoicePicker newVoice={"Cartoon"} callback={goToNextSlide} />
            <VoicePicker newVoice={"Seductive"} callback={goToNextSlide} />
            <VoicePicker newVoice={"Jeff Goldblum"} callback={goToNextSlide} />
            <VoicePicker newVoice={"Sound Off"} callback={goToNextSlide} />
          </div>
        </div>
      </div>
    </Slide>
  )
}

export default VoicesSlide
